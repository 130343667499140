"use client";
import { searchCollections } from "@/server/actions/collection/search/pgSearch";
import { fetchEnsUsername } from "@/server/actions/ens";
import { isEVMAddress } from "@/utils/network";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useState } from "react";
import { create } from "zustand";
import IconSearch from "../Icons/IconSearch";
import LoadingDots from "../Loading/LoadingDots";
import Modal from "../Modal";
import Spacer from "../Spacer";
import Contact from "./Contact";
import GoDirectToCollection from "./GoDirectToCollection";
import Guides from "./Guides";
import Jokes from "./Jokes";
import PopularSearches from "./PopularSearches";
import RecentSearches from "./RecentSearches";
import Results from "./Results";
import SectionHeading from "./SectionHeading";
interface SearchModalState {
  searchModal: boolean;
  setSearchModal: (searchModal: boolean) => void;
}

export const useSearchModalStore = create<SearchModalState>((set) => ({
  searchModal: false,
  setSearchModal: (searchModal) => set({ searchModal }),
}));

interface CollectionResult {
  name: string;
  image: string;
  address: string;
  network: string;
}

export default function Search() {
  const { searchModal, setSearchModal } = useSearchModalStore();

  const [loading, setLoading] = useState(false);
  const [uniqueCollections, setUniqueCollections] = useState<
    CollectionResult[] | null | any[]
  >([]);

  const [query, setQuery] = useState("");

  // Reset query when modal is closed
  useEffect(() => {
    if (searchModal === false) {
      setQuery("");
    }
  }, [searchModal]);

  const handleSearch = useCallback(
    debounce(async (query) => {
      if (query.length >= 3) {
        setLoading(true);
        const collections = await searchCollections(query);
        setUniqueCollections(collections);
        setLoading(false);
      } else if (query.length < 3) {
        setUniqueCollections([]);
        setLoading(false);
      }
    }, 350),
    []
  );

  useEffect(() => {
    if (!query) {
      setUniqueCollections([]);
      setWalletAddress("");
      setLoading(false);
      return;
    }
    if (query.length < 3) {
      setUniqueCollections([]);
      setWalletAddress("");
      setLoading(false);
      return;
    }
    handleSearch(query);
  }, [query, handleSearch]);

  // TO DO: Find address based on .eth ens name
  // const isENS = query.includes(".eth");

  // TO DO: Get user avatar for wallet address
  const [walletAddress, setWalletAddress] = useState<string>("");
  const [ensName, setEnsName] = useState<string | null>("");

  useEffect(() => {
    if (query && query.length >= 3) {
      if (isEVMAddress(query)) {
        setWalletAddress(query);
        const fetchENS = async () => {
          setEnsName(await fetchEnsUsername(query));
        };
        fetchENS();
      }
    } else if (query.length < 3) {
      setUniqueCollections([]);
      setWalletAddress("");
    }
  }, [query]);

  const handleRecentSearchClick = (searchQuery) => {
    setQuery(searchQuery);
    handleSearch(searchQuery);
  };

  return (
    <>
      <div className="flex gap-x-[24px] justify-center group mr-0">
        <button
          className="border-[3px] border-transparent relative cursor-pointer flex-nowrap whitespace-nowrap text-center font-500 transition-colors 
                duration-300 ease-in-out text-white  rounded-lg xl:min-w-[320px] lg:w-full sm:py-2.5 
              sm:px-4 sm:text-center sm:text-14 hover:border-dark-pri  sm:bg-dark-pri/10 "
          onClick={() => setSearchModal(true)}
        >
          <div className="flex items-center gap-x-2 italic transition-color duration-300 ease-in-out text-light-gray group-hover:text-white">
            <IconSearch />
            <span className="hidden lg:flex">
              Search games, NFTs, 0x123 ...
            </span>
            <span className="hidden sm:flex lg:hidden">Search ...</span>
          </div>
        </button>
      </div>
      <Modal
        open={searchModal}
        setOpen={setSearchModal}
        width="!w-full sm:min-w-[600px] md:!max-w-[97%]"
      >
        <div className="py-8 sm:pt-0 sm:w-auto">
          <div className="flex flex-col sm:grid sm:grid-cols-3 ">
            <div className="sm:col-span-2 gap-x-4 sm:pr-6">
              <div className="relative">
                <form
                  action={async (formData) => {
                    try {
                      await handleSearch(formData.get("search") as string);
                    } catch (error) {
                      console.log;
                    }
                  }}
                  className="rounded-md hover:border-dark-pri border border-dark-default-1 w-full  relative text-white "
                >
                  <MagnifyingGlassIcon className="absolute left-3 top-1/2 -translate-y-1/2 w-[22px]" />
                  <label htmlFor="search" className="sr-only">
                    search
                  </label>
                  <input
                    type="text"
                    id="search"
                    name="search"
                    autoFocus={true}
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    className="text-base pl-12 py-5 w-full bg-transparent ring-0 border-transparent 
          focus:border-transparent focus:outline-none focus:ring-2 focus:ring-dark-pri text-white placeholder:text-white 
           rounded-md"
                    placeholder="Search..."
                  />
                </form>
                {query && query.length > 3 && (
                  <button
                    onClick={() => setQuery("")}
                    className="absolute right-3 top-1/2 -translate-y-1/2 z-10 cursor-pointer flex h-8 w-8 items-center justify-center rounded-md 
                  p-2 text-light-gray hover:bg-gray-50/10 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                )}
              </div>
              {/* @ts-ignore */}
              <RecentSearches search={handleRecentSearchClick} />

              {loading && (
                <div className="flex py-12 items-center justify-center">
                  <LoadingDots />
                </div>
              )}
              {((walletAddress && walletAddress?.length > 0) ||
                (uniqueCollections && uniqueCollections?.length > 0)) && (
                <Results
                  uniqueCollections={uniqueCollections}
                  query={query}
                  walletAddress={walletAddress}
                  ensName={ensName}
                />
              )}

              <div className="mt-8" />
              {/* @ts-ignore */}
              <PopularSearches search={handleRecentSearchClick} />

              <GoDirectToCollection />
            </div>
            <div className="flex flex-col w-full relative sm:border-l border-white/10 sm:pl-4 md:pl-8">
              <div className="rounded-full absolute -top-1/3 rotate-[22deg] -right-12 w-[70%] h-[100%] blur-2xl bg-gradient-to-b from-dark-pri/20 to-dark-pri/0 " />
              <Guides />
              <Spacer size={32} />
              <Contact setSearchModal={setSearchModal} />

              <Spacer size={32} />
              <SectionHeading title="Bored?" icon="⁉️" />
              <Spacer size={16} />
              <Jokes />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
