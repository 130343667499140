"use client";
import SmolPrimaryBtn from "../Forms/Button/SmolPrimaryBtn";
import SectionHeading from "./SectionHeading";

type Props = {
  search: (querySearch: any) => Promise<void>;
};

export default function PopularSearches({ search }: Props) {
  const POPULAR_SEARCHES = [
    "Meebits",
    // "Milady Maker",
    "My Pet Hooligan",
    "DeGods",
    // "Parallel Planetfall",
    // "Nounish Punk",
    "pARticle pack",
    // "Champions Arena",
    // "This Thing Of Ours - The NFT Gaming Collective",
    // "Doodles",
    // "y00ts",
    // "ChronoForge",
    "Azuki",
    "Sugartown",
    "ANOMALY",
    "Pixels",
    "Bored",
    "Pudgy",
    "punk",
  ];
  return (
    <div className="grid  pb-2 gap-4">
      <SectionHeading title="Popular Searches" icon="❤️" />
      <div className="flex flex-wrap gap-x-4 pt-4 gap-y-4">
        {POPULAR_SEARCHES.map((query) => (
          <SmolPrimaryBtn
            key={query}
            onClick={() => search(query)}
            ariaLabel={`Search for ${query}`}
          >
            {query}
          </SmolPrimaryBtn>
        ))}
      </div>
    </div>
  );
}
